import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Color } from "../../../../../styles";

const Service = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  background-color: #000;

  &:hover {
    cursor: pointer;
    a > .gatsby-image-wrapper {
      opacity: 1;
    }

    a > .ReadMore {
      background-color: rgba(255, 255, 255, 0.9);
      color: rgba(0, 0, 0, 0.5);
      text-shadow: none;
    }
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    color: ${Color.TEXT_WHITE_PRIMARY};
    text-decoration: none;

    > .gatsby-image-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      opacity: 0.7;
      transition: opacity 0.3s ease-in-out;
    }
  }
`;

const H3 = styled.h3`
  position: absolute;
  font-size: 1.375rem;
  border-bottom: 1px solid #f2521b;
  padding-bottom: 8px;
  margin-bottom: 24px;
  text-shadow: 0 0 4px rgba(0, 0, 0, 1);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
`;

const ReadMore = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 128px;
  height: 32px;
  padding: 0 24px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.9);
  border-radius: 14px;
  font-size: 0.875rem;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 912px) {
    bottom: 48px;
  }
`;

export default ({ image, title, link, children }) => (
  <Service className={"Service"}>
    <Link className="" to={link}>
      {children}
      {/*<Image
            className="Image"
            style={{ backgroundImage: `url('${image}')` }}
          /> */}
      <H3>{title}</H3>
      <ReadMore className="ReadMore">Läs mer</ReadMore>
    </Link>
  </Service>
);
