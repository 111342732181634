import React from "react";
import styled from "styled-components";

import Testimonials from "../Testimonials/Testimonials";
import ContactButton from "../../general/ContactButton/ContactButton";

const Introduction = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding: 64px 0 72px 0;
  background-color: #222;
  box-shadow: 0 0 12px 12px rgba(0, 0, 0, 0.1);
  clip-path: polygon(0 0, 100% 24px, 100% calc(100% - 24px), 0 100%);
`;
const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  max-width: 912px;
  margin: 0 auto;

  @media only screen and (max-width: 960px) {
    width: auto;
    margin: 0 24px;
  }
`;
const TestimonialColumn = styled.div`
  // padding-top: 48px;
  a {
    display: block;
    width: max-content;
    margin: 0 36px 0 auto;

    font-size: 0.85rem;
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
  }
`;

const TextColumn = styled.div`
  flex: 1 1 460px;
  margin-right: 60px;

  @media only screen and (max-width: 960px) {
    margin: 12px 0;
  }
`;

const H1 = styled.h1`
  width: max-content;
  font-size: 2rem;
  letterspacing: 0.3rem;
  font-weight: 600;

  @media only screen and (max-width: 960px) {
    width: auto;
    line-height: 130%;
  }
`;

export default () => (
  <Introduction>
    <Columns>
      <TextColumn>
        <H1>Vi är DJs som skapar upplevelser</H1>
        <p style={{ marginBottom: 24 }}>
          Vi är med från start till slut - idé till genomförande. Vår vision är
          att du ska bli så nöjd som möjligt. Vi vill leverera
          helhetsupplevelser där kundkontakt alltid står i fokus. Inför ett
          event hjälper vi dig med alla detaljer, särskilt om det är första
          gången du bokar DJ.
        </p>
      </TextColumn>
      <TestimonialColumn style={{ flex: "1 1 280px" }}>
        <Testimonials
          testimonials={[
            {
              name: "Oskar",
              content:
                "Platoon DJs var grymma. Det var svårt att få gästerna att gå hem!"
            },
            {
              name: "Maja",
              content:
                "Vi var supernöjda! Jättetacksam för att ni var så flexibla och ordnade med allt. Kommer absolut rekommendera Platoon DJs!"
            }
          ]}
        />
      </TestimonialColumn>
    </Columns>
    <ContactButton />
  </Introduction>
);
