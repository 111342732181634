import React from "react";

import Header from "../components/general/Header/Header";
import Footer from "../components/general/Footer/Footer";
import Contact from "../components/general/Contact/Contact";
import Banner from "../components/general/Banner/Banner";

import video from "../assets/videos/bannerVideoCompressed3.mp4";

import Introduction from "../components/index/Introduction/Introduction";
import Services from "../components/index/Services/Services";
import About from "../components/index/About/About";
import CustomHelmet from "../components/general/CustomHelmet/CustomHelmet";

export default () => (
  <div>
    <CustomHelmet
      title="Hyra DJ Stockholm - Platoon DJs"
      description="Hyra DJ i Stockholm"
      keywords="dj, hyra, stockholm"
      canonical=""
    />
    <Header />
    <Banner
      fullHeight={true}
      videoUrl={video}
      tagline="Bästa DJs i Stockholm på"
      fadeTexts={[
        "50-årsfest",
        "Studentskiva",
        "Företagsevent",
        "Födelsedagsfest",
        "Bröllop",
        "Studentflak"
      ]}
    />
    <Introduction />
    <Services />
    <About />
    <Contact />
    <Footer />
  </div>
);
