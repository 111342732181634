import React from "react";

import * as Styles from "./Testimonials.styles";

export default class Testimonials extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0
    };
  }

  render() {
    return (
      <Styles.Container>
        {this.props.testimonials.map((t, i) => (
          <div key={"testimonial" + i}>
            <Styles.Content>{t.content}</Styles.Content>
            <Styles.Name>{t.name}</Styles.Name>
            {/*<Testomial />*/}
          </div>
        ))}
      </Styles.Container>
    );
  }
}
