import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Quote from "../../general/Quote/Quote";

const About = styled.div`
  position: relative;
  z-index: 1;
  margin: -48px auto;
  padding: 64px 0 96px 0;
  background-color: #222;
  box-shadow: 0 0 12px 12px rgba(0, 0, 0, 0.1);
  clip-path: polygon(0 24px, 100% 0, 100% 100%, 0 calc(100% - 48px));
`;

const Columns = styled.div`
  display: flex;

  align-items: center;
  max-width: 800px;
  margin: 0 auto;

  @media only screen and (max-width: 960px) {
    flex-wrap: wrap;
    width: auto;
    margin: 0 24px;
  }
`;
const TextColumn = styled.div`
  flex: 1 1 auto;
`;

const PhotoColumn = styled.div`
  flex: 1 1 200px;
  margin-right: 96px;

  @media only screen and (max-width: 960px) {
    margin: 0 auto 48px auto;
  }

  > .gatsby-image-wrapper {
    display: block;
    width: 200px;
    height: 200px;
    border: 6px solid rgba(255, 255, 255, 0.9);
    border-radius: 50%;

    @media only screen and (max-width: 960px) {
      margin: 0 auto;
    }
  }
`;

const H2 = styled.h2`
  width: max-content;
  font-size: 2rem;
  letterspacing: 0.3rem;
  font-weight: 600;
  @media only screen and (max-width: 960px) {
    width: auto;
    line-height: 130%;
  }
`;

export default () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "index/groupPhoto.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200, height: 200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <About>
        <Columns>
          <PhotoColumn>
            <Img
              fixed={data.file.childImageSharp.fixed}
              style={{ display: "block" }}
              alt="Platoon DJs"
              title="Platoon DJs"
            />
          </PhotoColumn>
          <TextColumn>
            <H2>Skräddarsytt för dig</H2>
            <p>
              För att vara en komplett partner inom DJ-branschen krävs många
              olika kompetenser. Det har vi samlat på oss under de 30 år som vi
              funnits i branschen och vi är vana med alla slags event. Allt från
              After Work till sedvanliga bröllop. Ja, vi har till och med spelat
              på Nobels efterfest. Därför kan vi hjälpa dig med enstaka tjänster
              eller en helhetslösning. Vi tillhandahåller en komplett lösning
              för ditt event, ljud, ljus och erfarna DJs. Vi skräddarsyr ett
              paket som passar just dina preferenser.
            </p>

            <Quote marginTop={24} textAlign="left" name="Magnus">
              Vi är mycket nöjda med er insats och kan starkt rekommendera
              Platoon DJs!
            </Quote>
          </TextColumn>
        </Columns>
      </About>
    )}
  />
);
