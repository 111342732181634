import styled from "styled-components";

export const Container = styled.div``;

export const Testimonial = styled.div`
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  font-size: 0.875rem;
  line-height: 140%;
`;

export const Name = styled.p`
  font-size: 0.875rem;
  text-align: right;
  margin: 6px 12px 12px;
`;
