import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Service from "./components/Service/Service";

const Services = styled.div`
  display: flex;
  align-items: center;
  margin-top: -24px;
  flex-wrap: wrap;

  > .Service {
    flex: 1 1 33%;

    @media only screen and (max-width: 912px) {
      margin-top: -25px;
      flex: 1 1 100%;
      height: 200px;
      clip-path: polygon(0 24px, 100% 0, 100% calc(100% - 24px), 0 100%);
    }
  }
`;

export default () => (
  <StaticQuery
    query={graphql`
      query {
        weddingParty: file(relativePath: { eq: "index/weddingParty.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxHeight: 400, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        companyEvent: file(relativePath: { eq: "index/companyEvent.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxHeight: 400, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        privateParty: file(relativePath: { eq: "index/privateParty.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxHeight: 400, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Services>
        <Service title="Bröllop" link="/brollop/" flex="1 1 33%">
          <Img
            fluid={data.weddingParty.childImageSharp.fluid}
            alt="Boka DJ Bröllop"
            title="Boka DJ Bröllop"
          />
        </Service>
        <Service title="Företagsevent" link="/foretagsevent/" flex="1 1 33%">
          <Img
            fluid={data.companyEvent.childImageSharp.fluid}
            alt="Boka DJ Företagsevent"
            title="Boka DJ Företagsevent"
          />
        </Service>
        <Service title="Privatfest" link="/privatfest/" flex="1 1 33%">
          <Img
            fluid={data.privateParty.childImageSharp.fluid}
            alt="Boka DJ Fest"
            title="Boka DJ Fest"
          />
        </Service>
      </Services>
    )}
  />
);
